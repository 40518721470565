import React, { useEffect, useState } from "react";
import P1 from "./styled/P1";
import colors from "./colors";
import styled from "styled-components";

async function fetchPrivacyPolicy(endpoint) {
  return fetch(endpoint)
    .then(data => data.json())
    .then(data => data.content);
}

const PrivacyWrapper = styled.div`
  font-size: 1.2em;
  color: ${colors.dark};
  line-height: 1.6;
`;

const PrivacyPolicy = props => {
  const { endpoint, url } = props;

  const [privacyPolicy, setPrivacyPolicy] = useState("");
  useState;

  useEffect(() => {
    (async function() {
      const privacyPolicyContent = await fetchPrivacyPolicy(endpoint);
      setPrivacyPolicy(privacyPolicyContent);
    })();
  }, []);

  return (
    <div>
      <PrivacyWrapper dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
      <br />
      <P1>
        Trouble seeing this page? <a href={url}>Click here to view it</a>.
      </P1>
      <P1>
        <a href="https://unread.it">Visit our homepage</a>.
      </P1>
    </div>
  );
};

export default PrivacyPolicy;
